import { Outlet } from "react-router-dom";
import {
  ClipboardDocumentListIcon,
  FolderIcon,
  CloudArrowUpIcon,
} from "@heroicons/react/24/outline";
import Tabs from "../../common/components/layout/Tabs";

export default function InventoryManager() {
  return (
    <>
      <div className="my-10">
        <Tabs
          tabs={[
            {
              id: "inventory",
              icon: ClipboardDocumentListIcon,
              text: "View Inventory",
              href: "/inventory",
            },
            {
              id: "past_uploads",
              icon: FolderIcon,
              text: "Past File Uploads",
              href: "/inventory/uploads",
            },
            {
              id: "upload",
              icon: CloudArrowUpIcon,
              text: "Upload Inventory",
              href: "/inventory/upload",
            },
          ]}
        />
      </div>
      <Outlet />
    </>
  );
}
