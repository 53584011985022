import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Supplier } from "../../common/models/supplier";
import { userState } from "../../common/recoilStateDefs";
import { useRecoilState } from "recoil";
import {
  ApiRequestOptions,
  sendAuthenticatedRequest,
} from "../../common/functions";
import FormTextInput from "../../common/components/formInputs/FormTextInput";

export default function SupplierDetailsForm() {
  const [submissionError, setSubmissionError] = useState<string | null>();
  const [user, setUser] = useRecoilState(userState);

  const handleSignup = (
    { businessName, address }: { businessName: string; address: string },
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmissionError(null);
    const errorHandler = (resp: Response | Error) => {
      if ((resp as Error).message !== undefined) {
        setSubmissionError(`Error submitting: ${(resp as Error).message}`);
      } else {
        setSubmissionError("Something went wrong :/");
      }
      setSubmitting(false);
    };
    const successHandler = async (resp: Response) => {
      const supplier = (await resp.json()) as Supplier;
      if (!user) {
        errorHandler(new Error("Authentication error"));
        return;
      }
      setUser({ ...user, supplier });
      setSubmitting(false);
    };

    const requestOptions: ApiRequestOptions = {
      method: "POST",
      jsonBody: {
        businessName,
        address,
      },
    };

    const url = `${process.env.REACT_APP_USER_API_BASE_URL}/suppliers`;

    sendAuthenticatedRequest(url, requestOptions)
      .then(successHandler)
      .catch(errorHandler);
  };

  return (
    <div className="mx-auto md:w-[30em] px-4 py-10">
      <Formik
        initialValues={{
          businessName: "",
          address: "",
        }}
        validationSchema={Yup.object({
          businessName: Yup.string()
            .max(50, "Must be 50 characters or less")
            .required("Name is required"),
          address: Yup.string()
            .max(200, "Must be 200 charcters or less")
            .required("Email is required"),
        })}
        onSubmit={(values, { setSubmitting }) =>
          handleSignup(values, setSubmitting)
        }
      >
        {(formik) => (
          <Form>
            <FormTextInput
              label="Company name"
              name="businessName"
              type="text"
            />

            <FormTextInput label="Address" name="address" type="text" />

            <button
              type="submit"
              className="py-2 bg-slate-800 block w-full rounded text-[#f1f5f9] disabled:bg-slate-600"
              disabled={formik.isSubmitting}
            >
              Submit
            </button>
            <div className="text-sm text-[#b91c1c] mt-3 min-h-[1.8em]">
              {submissionError || ""}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
