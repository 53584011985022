import { ChangeEvent, ComponentType, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../../functions";
import { bestMenuMatchByUrl } from "./helpers";

export default function Tabs({ tabs }: TabsProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeItemId, setActiveItemId] = useState<string | undefined>();

  useEffect(() => {
    const explicitlyActiveItems = tabs.filter((item) => item.isActive);
    if (explicitlyActiveItems && explicitlyActiveItems.length > 0) {
      setActiveItemId(explicitlyActiveItems[0].id);
      return;
    }

    // No item has been explicitly marked as active, so infer this from the URLs.
    setActiveItemId(bestMenuMatchByUrl(location.pathname, tabs)?.id);
  }, [location]);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const f = tabs.filter((t) => t.id === e.target.value);
    if (f && f.length) navigate(f[0].href);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((t) => t.id === activeItemId)?.id}
          onChange={handleSelectChange}
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {tab.text}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.id}
              to={tab.href}
              className={classNames(
                tab.id === activeItemId
                  ? "bg-gray-100 text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium group inline-flex",
              )}
              aria-current={tab.id === activeItemId ? "page" : undefined}
            >
              {tab.icon && (
                <tab.icon
                  className={classNames(
                    tab.id === activeItemId
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5",
                  )}
                  aria-hidden="true"
                />
              )}
              <span>{tab.text}</span>
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
}

// This is to make Typescript happy.
type MenuIconTypeProps = {
  className: string | undefined;
};

type TabsProps = {
  tabs: {
    id: string;
    text: string;
    icon?: ComponentType<MenuIconTypeProps>;
    isActive?: boolean;
    href: string;
  }[];
};
