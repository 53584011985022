import {
  Timestamp,
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { userState } from "../../common/recoilStateDefs";
import { useRecoilValue } from "recoil";
import SimpleTable from "../../common/components/SimpleTable/SimpleTable";

type InventoryUploadData = {
  uploadId: string;
  createdAt: Timestamp;
  fileUploadMetadata: {
    uploadedBy: {
      email: string;
    };
    originalFilename: string;
  };
  totalNumUpdates?: number;
  numRejectedUpdates?: number;
  numPublishedUpdates?: number;
  statusString?: string;
};

export default function InventoryUploadTable() {
  const [data, setData] = useState<InventoryUploadData[] | undefined>(
    undefined,
  );
  const user = useRecoilValue(userState);

  useEffect(() => {
    const collectionRef = collection(
      db,
      `suppliers/${user?.supplier?.id}/inventory-upload-metadata`,
    );
    const q = query(
      collectionRef,
      where("uploadType", "==", "file"),
      orderBy("createdAt", "desc"),
    );
    const unsubscribe = onSnapshot(q, (snap) => {
      setData(snap.docs.map((d) => d.data() as InventoryUploadData));
    });

    return () => unsubscribe();
  }, []);

  const determineStatus = (iud: InventoryUploadData) => {
    if (iud.statusString) {
      if (iud.statusString === "id_validation_fail") {
        return <span className="text-red-700">Failed product ID checks</span>;
      }
      return <>Unknown</>;
    }
    if (iud.totalNumUpdates === -1) return <>Processing</>;
    if (!iud.totalNumUpdates) return <>Unkown</>;
    const numPending =
      (iud.totalNumUpdates || 0) -
      (iud.numPublishedUpdates || 0) -
      (iud.numRejectedUpdates || 0);
    return (
      <>
        {!!iud.numPublishedUpdates && (
          // TODO the Inventory Upload Metadata doc no longer contains the numPublishedUpdates field. Need to rework this.
          <div className="text-green-700">{iud.numPublishedUpdates} live</div>
        )}
        {!!numPending && (
          <div className="text-orange-700">{numPending} being processed</div>
        )}
        {!!iud.numRejectedUpdates && (
          <div className="text-red-700">{iud.numRejectedUpdates} rejected</div>
        )}
      </>
    );
  };

  if (data === undefined) {
    return <div>Loading</div>;
  }

  if (data.length === 0) {
    return <>No uploads yet</>;
  }

  return (
    <SimpleTable
      columnTitles={["Date", "File name", "Uploaded by", "Status"]}
      data={data.map((iud) => {
        return {
          Date: iud.createdAt.toDate().toDateString(),
          "File name": iud.fileUploadMetadata.originalFilename,
          "Uploaded by": iud.fileUploadMetadata.uploadedBy.email,
          Status: determineStatus(iud),
        };
      })}
    />
  );
}
