import { ReactNode } from "react";
import { classNames } from "../../functions";

export default function SimpleTable({
  columnTitles,
  data,
  tableId,
}: SimpleTableProps) {
  const tableIdRandomized = tableId || Math.random().toString();

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {columnTitles.map((ct, i) => (
                    <th
                      key={`${tableIdRandomized}-title-${ct}`}
                      scope="col"
                      className={classNames(
                        i === 0 ? "pl-4 pr-3 sm:pl-6" : "px-3",
                        "py-3.5 text-left text-sm font-semibold text-gray-900",
                      )}
                    >
                      {ct}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((row, i) => (
                  <tr key={`${tableIdRandomized}-row-${i}`}>
                    {columnTitles.map((ct, j) => (
                      <td
                        key={`${tableIdRandomized}-row-${i}-col-${ct}`}
                        className={classNames(
                          j === 0 ? "sm:pl-6 pl-4 pr-3" : "px-3",
                          "whitespace-nowrap py-4 text-sm text-gray-500",
                        )}
                      >
                        {row[ct]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

type SimpleTableProps = {
  columnTitles: string[];
  data: { [columnTitle: string]: string | ReactNode }[];
  // used to construct React node keys
  tableId?: string;
};
