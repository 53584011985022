import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import SignOut from "./common/components/userAuth/SignOut";
import UserAuthPage from "./common/components/userAuth/UserAuthPage";
import PortalContainer from "./components/PortalContainer/PortalContainer";
import InventoryManager from "./components/InventoryManager/InventoryManger";
import Dashboard from "./components/Dashboard/Dashboard";
import InventoryUploadTable from "./components/InventoryManager/InventoryUploadTable";
import InventoryUpload from "./components/InventoryUpload/InventoryUpload";
import InventoryTable from "./components/InventoryManager/InventoryTable";
import Settings from "./components/Settings/Settings";
import OrganizationSettings from "./components/Settings/OrganizationSettings";
import BasicSettings from "./components/Settings/BasicSettings";
import Redirect from "./common/components/Redirect/Redirect";
import logoPlaceholder from "./assets/img/cm-logo.png";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: "signup",
    element: <UserAuthPage kind="signup" logoImg={logoPlaceholder} />,
  },
  {
    path: "signin",
    element: <UserAuthPage kind="signin" logoImg={logoPlaceholder} />,
  },
  {
    path: "signout",
    element: <SignOut />,
  },
  {
    path: "/",
    element: <PortalContainer />,
    children: [
      {
        path: "inventory",
        element: <InventoryManager />,
        children: [
          {
            path: "uploads",
            element: <InventoryUploadTable />,
          },
          {
            path: "upload",
            element: <InventoryUpload />,
          },
          {
            path: "",
            element: <InventoryTable />,
          },
        ],
      },
      {
        path: "settings",
        element: <Settings />,
        children: [
          {
            path: "organization",
            element: <OrganizationSettings />,
          },
          {
            path: "",
            element: <BasicSettings />,
          },
        ],
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "",
        element: <Redirect to="/dashboard" />,
      },
      {
        path: "*",
        element: <>Page not found</>,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
