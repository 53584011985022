import { ref, uploadBytes } from "firebase/storage";
import { supplierFileBucket, db } from "../../firebase";
import FileUpload from "../FileUpload/FileUpload";
import { userState } from "../../common/recoilStateDefs";
import { useRecoilValue } from "recoil";
import { sendAuthenticatedRequest } from "../../common/functions";

export default function InventoryUpload() {
  const user = useRecoilValue(userState);

  const validateFile = (file: File): string | undefined => {
    if (
      !file.type.startsWith("text/csv") &&
      !file.type.startsWith("application/pdf") &&
      !file.type.startsWith(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      )
    ) {
      console.error("Invalid file type", file.type);
      return "Only CSV and PDF uploads are supported!";
    }
  };

  const handleFileUpload = async (
    fl: FileList | null | File[],
    successHandler: () => void,
    errorHandler: (err: string) => void,
  ): Promise<void> => {
    if (!fl || fl.length === 0) {
      return;
    }
    // validate all files
    const vErrs = Array.from(fl).flatMap((file) => {
      const vErr = validateFile(file);
      if (vErr) {
        return [vErr];
      }
      return [];
    });
    if (vErrs.length > 0) {
      errorHandler(vErrs[0]);
      return;
    }

    const uploadPromises = Array.from(fl).map(async (file) => {
      const { uploadId, createdAtIsoString } = await sendAuthenticatedRequest(
        `${process.env.REACT_APP_INVENTORY_API_BASE_URL}/suppliers/${user?.supplier?.id}/fileUploadMetadata`,
        {
          method: "POST",
          jsonBody: {
            originalFilename: file.name,
          },
        },
      ).then((resp) => resp.json());

      const isoDate = createdAtIsoString.substring(0, 10);
      const storageRef = ref(
        supplierFileBucket,
        `suppliers/${user?.supplier?.id}/uploads/${isoDate}-${uploadId}-${file.name}`,
      );
      return await uploadBytes(storageRef, file, {
        customMetadata: {
          uploadId,
          userId: user?.id || "",
          originalFilename: file.name,
        },
      });
    });

    Promise.all(uploadPromises)
      .then(successHandler)
      .catch((err) => errorHandler(`Error uploading files: ${err}`));
  };

  return <FileUpload fileHandler={handleFileUpload} />;
}
