import {
  ComponentType,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { classNames } from "../../functions";
import { Link, useLocation } from "react-router-dom";
import { bestMenuMatchByUrl } from "./helpers";

export default function PageWithSubMenuContainer({
  subMenuItems,
  children,
}: PropsWithChildren<PageWithSubMenuContainerProps>) {
  const location = useLocation();
  const [activeItemId, setActiveItemId] = useState<string | undefined>();

  useEffect(() => {
    const explicitlyActiveItems = subMenuItems.filter((item) => item.isActive);
    if (explicitlyActiveItems && explicitlyActiveItems.length > 0) {
      setActiveItemId(explicitlyActiveItems[0].id);
      return;
    }

    // No item has been explicitly marked as active, so infer this from the URLs.
    setActiveItemId(bestMenuMatchByUrl(location.pathname, subMenuItems)?.id);
  }, [location]);

  return (
    <div className="lg:flex lg:gap-x-16 lg:px-8">
      <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
        <nav className="flex-none px-4 sm:px-6 lg:px-0">
          <ul
            role="list"
            className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
          >
            {subMenuItems.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.href}
                  className={classNames(
                    item.id === activeItemId
                      ? "bg-gray-50 text-indigo-600"
                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                    "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold",
                  )}
                >
                  {!!item.menuIcon && (
                    <item.menuIcon
                      className={classNames(
                        item.isActive
                          ? "text-indigo-600"
                          : "text-gray-400 group-hover:text-indigo-600",
                        "h-6 w-6 shrink-0",
                      )}
                      aria-hidden="true"
                    />
                  )}
                  {item.menuText}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
      <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
        {children}
      </main>
    </div>
  );
}

// This is to make Typescript happy.
type MenuIconTypeProps = {
  className: string | undefined;
};

type SubMenuItem = {
  id: string;
  isActive?: boolean;
  menuIcon?: ComponentType<MenuIconTypeProps>;
  menuText: string | ReactNode;
  href: string;
};

type PageWithSubMenuContainerProps = {
  subMenuItems: SubMenuItem[];
};
