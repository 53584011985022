import { FormEvent, PropsWithChildren, useEffect, useState } from "react";
import { saveInputChangeInHookState } from "../../common/functions";

export default function UpdateableSetting(
  props: PropsWithChildren<UpdateableSettingProps>,
) {
  const [value, setValue] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newValue, setNewValue] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const [emptySubmit, setEmptySubmit] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState(false);

  useEffect(() => {
    loadValue();
  }, []);

  const loadValue = () => {
    setLoading(true);
    setError(false);
    props
      .getValue()
      .then((value) => {
        setLoading(false);
        setError(false);
        setValue(value);
        setNewValue(value);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setError(true);
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!newValue) {
      setEmptySubmit(true);
      return;
    }
    setSubmitting(true);
    setSubmissionErrors(false);

    props
      .setValue(newValue)
      .then((v) => {
        setSubmitting(false);
        setValue(newValue);
        setEditing(false);
      })
      .catch((err) => {
        setSubmitting(false);
        setSubmissionErrors(true);
      });
  };

  const genValueAndButton = () => {
    if (editing) {
      return (
        <form className="w-full" onSubmit={handleSubmit}>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <input
              type={props.isSecret ? "password" : "text"}
              value={newValue}
              onChange={saveInputChangeInHookState(setNewValue)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            {submissionErrors && (
              <div className="text-red-700 text-xs">Failed to save!</div>
            )}
            <button
              type="submit"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
              disabled={submitting}
            >
              Save
            </button>
          </dd>
        </form>
      );
    }
    if (loading) return <>loading...</>;
    if (error) return <div className="text-red-700">Could not load value</div>;
    return (
      <>
        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
          <div className="text-gray-900">
            {props.isSecret ? "•••••••••" : value || "-"}
          </div>
          <button
            type="button"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
            onClick={() => setEditing(true)}
          >
            Update
          </button>
        </dd>
      </>
    );
  };
  return (
    <div className="pt-6">
      <div className="sm:flex">
        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
          {props.label}
        </dt>
        {genValueAndButton()}
      </div>
      {props.children}
    </div>
  );
}

type UpdateableSettingProps = {
  label: string;
  getValue: () => Promise<string>;
  setValue: (value: string) => Promise<any>;
  isSecret?: boolean;
};
