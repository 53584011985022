import PageWithSubMenuContainer from "../../common/components/layout/PageWithSubMenuContainer";
import { Outlet } from "react-router-dom";
import {
  UserCircleIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";

export default function Settings() {
  return (
    <PageWithSubMenuContainer
      subMenuItems={[
        {
          id: "basic",
          menuIcon: UserCircleIcon,
          menuText: "Basic",
          href: "/settings",
        },
        {
          id: "organization",
          menuIcon: BuildingOffice2Icon,
          menuText: "Organization",
          href: "/settings/organization",
        },
      ]}
    >
      <Outlet />
    </PageWithSubMenuContainer>
  );
}
