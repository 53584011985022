import { PropsWithChildren, useState } from "react";

export default function LoadableSetting(
  props: PropsWithChildren<LoadableSettingProps>,
) {
  const [value, setValue] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadValue = () => {
    setLoading(true);
    setError(false);

    props
      .getValue()
      .then((value) => {
        setLoading(false);
        setError(false);
        setValue(value);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className="pt-6">
      <div className="sm:flex">
        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
          {props.label}
        </dt>
        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
          {error && <div className="text-red-700">Could not load value</div>}
          {loading && "loading..."}
          {!error && !loading && (
            <div className="text-gray-900">
              {value || props.placeholderText || "•••••••••"}
            </div>
          )}
          {value === undefined && (
            <button
              type="button"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
              disabled={loading}
              onClick={loadValue}
            >
              {props.loadButtonText || "Show"}
            </button>
          )}
        </dd>
      </div>
      {props.children}
    </div>
  );
}

type LoadableSettingProps = {
  getValue: () => Promise<any>;
  label: string;
  loadButtonText?: string;
  placeholderText?: string;
};
